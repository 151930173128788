<template>
  <brand-header :image="urlImage" :altImage="altImagen" />
  <header class="header">
    <div class="header-content max-container">
      <div class="header-content__nav">
        <!-- <menu-component/> -->
        <!-- <button class="hamburger-menu__button" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
          <span class="material-symbols-rounded">menu</span>
        </button> -->
        <a href="/" class="header-content__container-logo">
          <img class="header-content__img" src="../assets/images/logo.png" alt="wagerStore" />
        </a>
      </div>
      <div class="header-content__cta">

        <div class="header-buttons">
          <form class="login__form form__desktop" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
            <input class="login__form--inputs" type="text" placeholder="Usuario" name="account" id="account">
            <input class="login__form--inputs" type="password" placeholder="Contraseña" name="password" id="password">
            <button class="btn-login secondary-button secondary-button__betslip" type="submit">VERSIÓN CLÁSICA</button>
          </form>
          <form class="login__form form__mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
            <input class="login__form--inputs" type="text" placeholder="Usuario" name="account" id="account">
            <input class="login__form--inputs" type="password" placeholder="Contraseña" name="password" id="password">
            <button class="btn-login secondary-button secondary-button__betslip" type="submit">VERSIÓN CLÁSICA</button>
          </form>
        </div>
          <div class="header-buttons">
            <button class="btn-login secondary-button secondary-button__classic btn__new-version" @click="openModal">VERSIÓN NUEVA</button>
            <!-- <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">Betslip</a> -->
          </div>
      </div>
    </div>
    <div class="header-content__info">
      <ul class="header-content__info--list">
        <li class="header-content__info--list-item" @click="isActive = !isActive" v-for="(route, index) in routes"
          :key="index">
          <router-link class="fromleft" :to="route.route">{{ route.item }}</router-link>
        </li>
      </ul>
    </div>
    <ModalComponent ref="modal" />
  </header>
</template>

<script>
import ModalComponent from '@/components/modal.vue'
import BrandHeader from '@/components/brand-header.vue';
// import MenuComponent from '@/components/menu.vue'

export default {
  name: "HeaderComponent",
  components: {
    ModalComponent,
    BrandHeader
    // MenuComponent
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    }
  },
  setup() {
    const DGS_SITEID = 480;
    const backendUrl = "pick2score.com";
    return {
      DGS_SITEID,
      backendUrl,
      urlImage: require('../assets/images/logo.png'),
      altImage: 'wagerStore',

      routes: [
        {
          item: 'Apuestas en vivo',
          route: 'live-betting'
        },
        {
          item: 'casino',
          route: 'casino'
        },
        {
          item: 'Caballos',
          route: 'racebook'
        }
        // {
        //   item       :  'props builder',
        //   route      :  'props-builder'
        // }
      ]
    }
  },
};
</script>
